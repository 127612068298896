import React from "react";
import PropTypes from "prop-types";
import userStore from "../../stores/userStore"
import history from "../../helper/browserHistory";

import config from "../../config/main.config";
import {getTokenFromLocalStorage} from "../../helper/util";

import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Image from "semantic-ui-react/dist/commonjs/elements/Image";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Modal from "semantic-ui-react/dist/commonjs/modules/Modal";

class EditImageComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectModalOpen: false,
            selectImages: [],
            image: {link: ""}
        }
    }

    componentDidMount() {
        this.fetchFrontendIdImage();
        this.setState({...this.state, selectModalOpen: false});
    }

    fetchAllImages() {
        fetch(config.BASE_URL + "images/web", {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*"
            }
        }).then(response => {
            this.loading = false;
            if (response.status >= 200 && response.status < 300) {
                response.json().then(json => {
                    let allImages = [];
                    for (let i = 0; i < json.length; i++) {
                        allImages.push({
                            link: json[i]
                        })
                    }
                    this.setState({...this.state, selectImages: allImages});
                });

            } else {
                console.log(response.status)
            }
        }).catch(
            error => {
                console.log(error);

                if (error.status === 401) {
                    history.replace("/admin-login");
                }

            }
        );
    }

    fetchFrontendIdImage() {
        let frontendId = this.props.id;
        fetch(config.BASE_URL + "images/single/" + frontendId, {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*"
            }
        }).then(response => {
            this.loading = false;
            if (response.status >= 200 && response.status < 300) {
                response.json().then(json => {
                    if (json.hasOwnProperty("link")) {
                        this.setState({...this.state, image: {link: json.link}});
                    } else {
                        this.setState({...this.state, image: {link: ""}});
                    }
                });

            } else {
                console.log(response.status)
                //TODO: Alert?
            }
        }).catch(
            error => {
                console.log(error);
                if (error.status === 401) {
                    history.replace("/admin-login");
                }

            }
        );
    }

    deleteImage() {
        fetch(config.BASE_URL + "images/single/", {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            },
            body: JSON.stringify({frontendId: this.props.id})
        }).then(response => {
            if (response.status >= 200 && response.status < 300) {
                this.fetchFrontendIdImage()
            } else {
                console.log(response.status)
                //TODO: Alert?
            }
        }).catch(
            error => {
                console.log(error);
            }
        );
    }

    uploadEditImage(event) {
        let self = this;
        const data = new FormData();
        let files = Array.from(event.target.files);

        //Send files
        if (files.length > 0) {
            data.append("images", files[0]);
        } else {
            return;
        }

        fetch(config.BASE_URL + 'images/uploadImage/' + this.props.id, {
            method: "POST",
            body: data,
            headers: {
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            }
        }).then(function (response) {
            if (response.status >= 200 && response.status < 300) {
                self.fetchFrontendIdImage()
            }
        }).catch(() => {
        });

    }

    selectImage(link) {
        let self = this;
        //put links to component
        fetch(config.BASE_URL + 'images/single-selection/', {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            },
            body: JSON.stringify({frontendId: this.props.id, link: link})
        }).then(function (response) {
            if (response.status >= 200 && response.status < 300) {
                self.componentDidMount()
            }
        }).catch(() => {

        });
    }

    render() {
        let displayType = userStore.userFromServer !== null ? "admin" : "user";


        let contentToShow = <Grid> <Grid.Row>
            <Image
                style={{height: "intrinsic"}}
                src={this.state.image.link}
                fluid
            />
        </Grid.Row></Grid>;

        if (displayType === "admin") {
            let showSelectImages = this.state.selectImages.map((item, index) =>
                <Grid.Column key={index} width={5}>
                    <Image
                        src={item.link}
                        fluid
                        onClick={() => this.selectImage(item.link)}
                    />
                </Grid.Column>
            );

            contentToShow = <Grid>
                <Grid.Row>
                    <Grid.Column>
                        {this.state.image.link.length > 0 ?
                            <Button
                                negative
                                onClick={() => this.deleteImage()}
                                attached={"top"}
                                className={"gallery-delete-button"}>
                                Löschen
                            </Button>
                            :
                            null
                        }
                        <Image
                            src={this.state.image.link}
                            fluid
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <input type="file" id="file" className="inputfile"
                               onChange={this.uploadEditImage.bind(this)}
                               accept="image/*"
                               multiple/>
                        <Button.Group>
                            <Button
                                color={"orange"}
                                onClick={() => {
                                    this.fetchAllImages();
                                    this.setState({...this.state, selectModalOpen: true})
                                }}>
                                Auswählen
                            </Button>
                            <Button color={"teal"}>
                                <label htmlFor="file">
                                    Hochladen
                                </label>
                            </Button>
                        </Button.Group>
                    </Grid.Column>
                </Grid.Row>
                <Modal open={this.state.selectModalOpen}
                       style={{"margin": "auto"}}
                       onClose={() => this.setState({...this.state, selectModalOpen: false})} centered={true}>
                    <Modal.Header>{"Auswählen"}</Modal.Header>
                    <Modal.Content scrolling>
                        <Grid>
                            <Grid.Row>
                                {showSelectImages}
                            </Grid.Row>
                        </Grid>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button negative icon='delete'
                                onClick={() => this.setState({...this.state, selectModalOpen: false})}/>
                    </Modal.Actions>
                </Modal>
            </Grid>;
        }
        return (contentToShow);
    }
}

/*this.images.length > 1 ?
                    <div style={{"width": "100%", "textAlign": "center", "marginTop": "5px"}}>
                        {showDots}
                    </div>
                    :
                    null*/

EditImageComponent.propTypes = {
    id: PropTypes.string
};

EditImageComponent.defaultProps = {
    id: Math.random().toString()
};

export default EditImageComponent