import React from "react";
import TitleImageComponent from "../../components/image/TitleImageComponent";
import browserHistory from "../../helper/browserHistory";
//import EditSimpleValueComponent from "../../components/moap-components/EditSimpleValueComponent";
import EditHTMLComponent from "../../components/moap-components/EditHTMLComponent";
import {inject} from "mobx-react";
import EditImageComponent from "../../components/moap-components/EditImageComponent";
import config from "../../config/main.config";

import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Image from "semantic-ui-react/dist/commonjs/elements/Image";
import Breadcrumb from "semantic-ui-react/dist/commonjs/collections/Breadcrumb";

const PAGE_LINK = "Valentina";

@inject("stores")
class ValentinaPage extends React.Component {

    constructor(props) {
        super(props);
            this.state = {
                textStore: props.stores.textStore.texts,
                isLoading: true
            }
    }

    componentDidMount() {
        this.props.stores.trackingStore.addVisit(PAGE_LINK);
        window.scrollTo(0, 0);
    }

    render() {
        // const Seperator = "https://www.buergerstiftung-kreis-rv.de/images/seperator.png";

        return (
            <Grid>
                <TitleImageComponent
                    textStore={this.state.textStore}
                    namespace={'valentina'}
                    imageLinkId={"ValentinaPage-title-image-id-2"}
                    headerId={"ValentinaPage-title-header-id"}
                    subheaderId={"ValentinaPage-subtitle-header-id"}
                    small
                    separatorLink={"https://www.buergerstiftung-kreis-rv.de/images/valentina_round.png"}
                />
                <Grid.Row>
                    <Grid.Column width={2} only={'computer'}/>
                    <Grid.Column computer={14} tablet={16} mobile={16}>
                        <Breadcrumb className={"breadcrumb-container"}>
                            <Breadcrumb.Section className={"inactive-breadcrumb"} onClick={() => {
                                browserHistory.push("/");
                                this.setState({});
                            }}>
                                HOME</Breadcrumb.Section>
                            <Breadcrumb.Divider icon='right chevron'/>
                            <Breadcrumb.Section className={"active-breadcrumb"}>STIFTUNG VALENTINA</Breadcrumb.Section>
                        </Breadcrumb>
                    </Grid.Column>
                </Grid.Row>
                {/*
                <Grid.Row centered className={"page-row"}>
                    <Grid.Column computer={12} tablet={16} mobile={16} textAlign={"center"}>
                        <Image centered
                               src={Seperator}
                               size={"tiny"}
                        />
                        <h2>
                            <EditSimpleValueComponent id={"ValentinaPage-title-sector-1-id"}/>
                        </h2>
                    </Grid.Column>
                </Grid.Row>
                */}
                <Grid.Row centered className={"page-row"}>
                    <Grid.Column computer={6} tablet={8} mobile={16} textAlign={"center"}>
                        <Image
                            src={"https://www.buergerstiftung-kreis-rv.de/images/valentina.png"}
                        />
                        <p className={"text-block"}>
                            <EditHTMLComponent id={"ValentinaPage-icon-card-1-subheader-id"}
                                                                         namespace={'valentina'}/>
                        </p>
                    </Grid.Column>
                    <Grid.Column computer={6} tablet={8} mobile={16}>

                        <EditImageComponent id={"ValentinaPage-image-1-id"}/>


                        <EditImageComponent id={"ValentinaPage-image-2-id"}/>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row centered className={"page-row"}>
                    <Grid.Column computer={6} tablet={8} mobile={16} textAlign={"center"}>

                        <EditImageComponent textStore={this.state.textStore} id={"ValentinaPage-image-3-id"}/>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}

export default ValentinaPage